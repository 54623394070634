.contacto {
  overflow: hidden;
  padding-top: 70px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__hero {
    overflow: hidden;
    margin-bottom: 50px;
    
    @include mq(md) {
      margin-top: -100px;
      margin-bottom: 100px;
      height: 30vw;
    }

    img {
      width: 100%;

      @include mq(md) {
      }
    }
  }

  &__details {

    &__container {
      margin-bottom: 100px;
    }

    &__title,
    &__form-title {
      font-size: 26px;
      font-weight: 700;
      margin: 0;
      margin-bottom: 20px;
      color: $corpBlack;
      
      @include mq(md) {
        font-size: 40px;
      }
    }

    &__text {
      color: $corpBlack;
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 500;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__phone {
      margin-top: 40px;
      display: block;
      text-decoration: none;
      color: $corpBlack;
      font-size: 24px;
      font-weight: 500;
    }

    &__form {
      margin-bottom: 50px;

      @include mq(md) {
        margin-bottom: 100px;
      }
    }

    &__form-input {
      // margin-top: 50px;
      width: 100%;
      // border-bottom: 1px solid $corpBlack;
      // height: 50px;
      display: flex;
      align-items: center;

      @include mq(md) {
        width: 70%;
      }

      input {
        height: 100%;
        width: 100%;
        border: none;
        font-size: 24px;
        outline: none;
      }

      a {
        background: transparent;
        border: none;
        color: $blue;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        outline: none;
        display: block;
        text-decoration: none;
      }
    }
  }
}