.project {
  $p: &;

  width: 100%;
  height: 100%;

  @include mq(md) {
    display: flex;
    position: relative;
  }

  &__content {
    width: 100%;
    // opacity: .95;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    @include mq(md) {
      padding: 0;
      width: 50%;
      height: 100%;
      // padding-bottom: 53.2%;
      overflow: hidden;
      position: relative;
    }
  }

  &__wrapper {
    z-index: 2;
    text-align: center;
    width: calc(100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mq(md)  {
      position: absolute;
      top: calc(50% + 30px);
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 70%;
    }
  }

  &__index-container {
    display: flex;
    width: 120px;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;

    @include mq(md) {
      margin-bottom: 40px;
    }

    &:after {
      content: '';
      height: 1px;
      width: 60px;
      position: absolute;
      background: $white;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    span {
      color: $white;
      font-weight: 500;
      font-size: 18px;
      font-family: $altFont;
    }
  }

  &__title {
    font-size: 40px;
    margin: 0;
    margin-bottom: 20px;
    color: $white;
    z-index: 2;

    @include mq(md) {
      font-size: 50px;
      margin-bottom: 40px;
    }
  }

  &__quote {
    font-size: 24px;
    margin: 0;
    margin-bottom: 20px;
    color: $white;
    font-style: italic;

    @include mq(md) {
      font-size: 24px;
    }
  }

  &__quoter {
    margin: 0;
    margin-bottom: 20px;
    color: $white;
    font-family: $altFont;
    text-transform: uppercase;
    font-size: 14px;

    @include mq(md) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  &__mision {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 20px;
    color: $white;
    font-family: $altFont;

    @include mq(md) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  &__more-button {
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    font-family: $altFont;
    text-transform: uppercase;
    cursor: pointer;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__image {
    width: 100vw;
    height: 100vw;
    overflow: hidden;
    position: relative;

    @include mq(md) {
      overflow: hidden;
      position: absolute;
      height: calc(100% - 70px);
      right: 0;
      top: 70px;
    }

    img {
      position: absolute;
      width: auto;
      right: 0;
      height: 100%;
      }
  }

  &.light-blue {
    background: $lightBlue;

    #{$p}__content {
      background: $lightBlue;
    }
  }

  &.blue {
    background: $blue;

    #{$p}__content {
      background: $blue;
    }
  }

  &.purple {
    background: $purple;

    #{$p}__content {
      background: $purple;
    }
  }

  &.dark-blue {
    background: $darkBlue;

    #{$p}__content {
      background: $darkBlue;
    }
  }

  &.mid-blue {
    background: $midBlue;

    #{$p}__content {
      background: $midBlue;
    }
  }

  &.brown {
    background: $brown;

    #{$p}__content {
      background: $brown;
    }
  }

  &.light-purple {
    background: $lightPurple;

    #{$p}__content {
      background: $lightPurple;
    }
  }

  &.turquoise {
    background: $turquoise;

    #{$p}__content {
      background: $turquoise;
    }
  }

  &.green {
    background: $green;

    #{$p}__content {
      background: $green;
    }
  }

  &.dark-brown {
    background: $darkBrown;

    #{$p}__content {
      background: $darkBrown;
    }
  }

  &.lightest-purple {
    background: $lightestPurple;

    #{$p}__content {
      background: $lightestPurple;
    }
  }
}