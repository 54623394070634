.profile-page {
  $p: &;
  @include trans;

  width: 100%;
  background: $white;
  opacity: 0;
  pointer-events: none;
  overflow: scroll;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 70px);
  
  &.-visible {
    opacity: 1;
    pointer-events: all;
  }

  &__hero {
    width: 100%;
    background: $lightGray;

    &__container {
      width: 100%;
      position: relative;
      height: 0;
      padding-bottom: 40%;
      z-index: 1;
      
      @include mq(md) {
        overflow: hidden;
        padding-bottom: 35%;
      }
      
      img {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 100%;
        z-index: -1;
      }

      button {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate3d(0, -50%, 0);
        width: 50px;
        height: 50px;
        border: 2px solid $white;
        border-radius: 50%;
        z-index: 2;
        background: transparent;
        cursor: pointer;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1px;
          height: 50%;
          background: $white;
          display: block;
          
          &:nth-child(1) {
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          }
        }

        @include mq(md) {
          right: 5vw;
          left: auto;
          transform: translate3d(0, -50%, 0);
          width: 70px;
          height: 70px;
        }
      }
    }

    &__wrapper {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);

      @include mq(md) {
        left: 5vw;
      }

      a {
        i {
          color: $corpBlack;
          font-size: 24px;
        }
      }
    }

    &__title {
      font-size: 16px;
      color: $corpBlack;
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
      text-transform: uppercase;

      @include mq(md) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &__nombre {
      font-size: 20px;
      color: $corpBlack;
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
      padding-right: 40%;

      @include mq(md) {
        font-size: 60px;
        margin-bottom: 20px;
      }
    }
  }

  &__content {

    &__text-container {
      padding: 50px 0;

      @include mq(md) {
        padding: 100px 0;
      }
    }

    &__details {
      @include mq(md) {
        padding: 100px 0;
      }
    }

    &__text {
      font-size: 16px;
      margin: 0;
      margin-bottom: 18px;
      line-height: 1.5;
      font-weight: 500;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__details-wrapper {
      margin-bottom: 40px;
    } 
    
    &__details-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &__details-text {
      margin-bottom: 5px;
      text-decoration: none;
    }
  }

  &__footer {
    &__container {
      display: flex;
      padding: 20px 15px;
      height: 10vh;
      justify-content: space-between;
      border-top: 1px solid $lightestGray;
  
      @include mq(md) {
        padding: 40px 5vw;
        height: 100px;
      }
    }
  
    &__logo {
      height: 100%;
      display: block;
  
      img {
        height: 100%;
      }
    }
  
    &__social {
      display: block;
  
      i {
        font-size: 24px;
        color: $corpBlack;
      }
    }
  }
}