@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Bold.eot');
  src: url('/fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Bold.woff2') format('woff2'),
      url('/fonts/Raleway-Bold.woff') format('woff'),
      url('/fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ExtraBoldItalic.eot');
  src: url('/fonts/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-ExtraBoldItalic.woff2') format('woff2'),
      url('/fonts/Raleway-ExtraBoldItalic.woff') format('woff'),
      url('/fonts/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ExtraBold.eot');
  src: url('/fonts/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-ExtraBold.woff2') format('woff2'),
      url('/fonts/Raleway-ExtraBold.woff') format('woff'),
      url('/fonts/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Black.eot');
  src: url('/fonts/Raleway-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Black.woff2') format('woff2'),
      url('/fonts/Raleway-Black.woff') format('woff'),
      url('/fonts/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-BoldItalic.eot');
  src: url('/fonts/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-BoldItalic.woff2') format('woff2'),
      url('/fonts/Raleway-BoldItalic.woff') format('woff'),
      url('/fonts/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-BlackItalic.eot');
  src: url('/fonts/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-BlackItalic.woff2') format('woff2'),
      url('/fonts/Raleway-BlackItalic.woff') format('woff'),
      url('/fonts/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ExtraLight.eot');
  src: url('/fonts/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-ExtraLight.woff2') format('woff2'),
      url('/fonts/Raleway-ExtraLight.woff') format('woff'),
      url('/fonts/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Light.eot');
  src: url('/fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Light.woff2') format('woff2'),
      url('/fonts/Raleway-Light.woff') format('woff'),
      url('/fonts/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Italic.eot');
  src: url('/fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Italic.woff2') format('woff2'),
      url('/fonts/Raleway-Italic.woff') format('woff'),
      url('/fonts/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-LightItalic.eot');
  src: url('/fonts/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-LightItalic.woff2') format('woff2'),
      url('/fonts/Raleway-LightItalic.woff') format('woff'),
      url('/fonts/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ExtraLightItalic.eot');
  src: url('/fonts/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-ExtraLightItalic.woff2') format('woff2'),
      url('/fonts/Raleway-ExtraLightItalic.woff') format('woff'),
      url('/fonts/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Medium.eot');
  src: url('/fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Medium.woff2') format('woff2'),
      url('/fonts/Raleway-Medium.woff') format('woff'),
      url('/fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Regular.eot');
  src: url('/fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Regular.woff2') format('woff2'),
      url('/fonts/Raleway-Regular.woff') format('woff'),
      url('/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-SemiBold.eot');
  src: url('/fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-SemiBold.woff2') format('woff2'),
      url('/fonts/Raleway-SemiBold.woff') format('woff'),
      url('/fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-MediumItalic.eot');
  src: url('/fonts/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-MediumItalic.woff2') format('woff2'),
      url('/fonts/Raleway-MediumItalic.woff') format('woff'),
      url('/fonts/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-SemiBoldItalic.eot');
  src: url('/fonts/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-SemiBoldItalic.woff2') format('woff2'),
      url('/fonts/Raleway-SemiBoldItalic.woff') format('woff'),
      url('/fonts/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Thin.eot');
  src: url('/fonts/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-Thin.woff2') format('woff2'),
      url('/fonts/Raleway-Thin.woff') format('woff'),
      url('/fonts/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ThinItalic.eot');
  src: url('/fonts/Raleway-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Raleway-ThinItalic.woff2') format('woff2'),
      url('/fonts/Raleway-ThinItalic.woff') format('woff'),
      url('/fonts/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Black.eot');
  src: url('/fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Black.woff2') format('woff2'),
      url('/fonts/Inter-Black.woff') format('woff'),
      url('/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Bold.eot');
  src: url('/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Bold.woff2') format('woff2'),
      url('/fonts/Inter-Bold.woff') format('woff'),
      url('/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-ExtraBold.eot');
  src: url('/fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-ExtraBold.woff2') format('woff2'),
      url('/fonts/Inter-ExtraBold.woff') format('woff'),
      url('/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-ExtraLight.eot');
  src: url('/fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-ExtraLight.woff2') format('woff2'),
      url('/fonts/Inter-ExtraLight.woff') format('woff'),
      url('/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Light.eot');
  src: url('/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Light.woff2') format('woff2'),
      url('/fonts/Inter-Light.woff') format('woff'),
      url('/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.eot');
  src: url('/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Medium.woff2') format('woff2'),
      url('/fonts/Inter-Medium.woff') format('woff'),
      url('/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.eot');
  src: url('/fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-SemiBold.woff2') format('woff2'),
      url('/fonts/Inter-SemiBold.woff') format('woff'),
      url('/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.eot');
  src: url('/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Regular.woff2') format('woff2'),
      url('/fonts/Inter-Regular.woff') format('woff'),
      url('/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Thin.eot');
  src: url('/fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter-Thin.woff2') format('woff2'),
      url('/fonts/Inter-Thin.woff') format('woff'),
      url('/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

