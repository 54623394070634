.header {

    $h: &;

    @include trans;

    position: fixed;
    top: 0;
    left: 0;
    background: $white;
    width: 100%;
    z-index: 10000;
    height: 72px;
    padding: 22px 15px;
    
    @include mq(md) {
        padding: 0 5vw;
        height: 70px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__logo {
        @include trans;

        height: 40px;
        z-index: 10001;

        @include mq(md) {
            height: 40px;
        }

        img {
            @include trans;

            height: 40px;
            width: auto;
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform: translateX(-100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        z-index: 10000;

        @include mq(md) {
            position: static;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            transform: translateX(0);            
            background: transparent;
        }
    }

    &__list {
        display: none;

        @include mq(md) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &--mobile {
            display: block;
            
            @include mq(md) {
                display: none;
            }
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 0;
            }
    
        }

        @include mq(md) {
            margin-left: 40px;
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            font-size: 26px;
            color: $white;
            font-weight: 700;

            @include mq(md) {
                color: $corpBlack + #666;
                font-size: 18px;
            }            
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        z-index: 10001;

        @include mq(md) {
            display: none;
        }

        span {
            @include trans;
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span {
                background: $white;
            }

            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__logo {
            filter: grayscale(100%) brightness(1000%);
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }

    &.-scrolled {
        background: $blue;

        @include mq(md) {
            height: 70px;
        }

        #{$h}__logo {
            filter: grayscale(100%) brightness(1000%);

            @include mq(md) {
                height: 20px;
            }

            img {
                @include mq(md) {
                    height: 20px;
                }
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
