.home {
  padding-top: 70px;

  @include mq(md) {
    padding-top: 0;
  }

  &__purpose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    .-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      opacity: .7;
    }

    &__content {
      text-align: center;
      width: calc(100% - 30px);
      z-index: 1;
      position: relative;
      padding: 50px 0;
      
      @include mq(md) {
        width: 65%;
        padding: 0;
      }
    }

    &__emblem {
      margin-bottom: 40px;
    }

    &__title {
      font-size: 20px;
      font-weight: 700;

      @include mq(md) {
        font-size: 40px;
      }
    }

    &__text {
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      display: block;

      @include mq(md) {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    &__scroll-button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid $black;
      position: relative;
      display: none;
      margin-left: auto;
      margin-right: auto;
      pointer-events: none;

      @include mq(md) {
        display: block;
        pointer-events: all;
      }

      &:after {
        content: '';
        width: 50%;
        height: 2px;
        background: $black;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }
    
      &--mobile {
        display: block;
        pointer-events: all;
  
        @include mq(md) {
          display: none;
          pointer-events: none;
        }
      }
    }
  }

  &__portfolio {
    overflow: hidden;
    
    &__slide {
      position: relative;
      height: 100%;
    }
  }

  &__investment-philosophy {
    padding: 50px 0;

    @include mq(md) {
      padding: 150px 0;
    }

    &__title {
      text-align: center;
      font-size: 30px;
      margin: 0;
      margin-bottom: 20px;

      @include mq(md) {
        font-size: 60px;
      }
    }

    &__logo {
      text-align: center;
      text-transform: uppercase;
      color: $midGray;
      font-weight: 700;
      display: block;
      margin-bottom: 20px;

      @include mq(md) {
        margin-bottom: 40px;
      }
    }

    &__text {
      font-size: 18px;
      margin: 0;
      margin-bottom: 20px;

      @include mq(md) {
        font-size: 26px;
        margin-bottom: 40px;
      }
    }
  }

  &__team {
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &__wrapper-out{
      @include mq(md) {
        background: $white;
        display: flex;
        align-items: flex-end;
        // padding: 70px 0 0;
        height: 100vh;
      }
    }

    &__container {
      width: 100%;

      &:after {
        content: '';
        @include trans;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: $blue;
        opacity: 0;
        height: 100%;
        top: 0;
        display: none;

        @include mq(md) {
          display: block;
        }
      }

      &:hover,
      &:active,
      &:focus {
        &:after{
          @include mq(md) {
            opacity: .8;
          }
        }

        .home__team__wrapper {
          opacity: 1;
        }
      }

      @include mq(md) {
        position: relative;
        width: 50%;
        padding-bottom: 22.5%;
        height: 0;
      }
    }

    &__image {
      width: 100%;
      
      @include mq(md) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block
      }
    }

    &__wrapper {
      @include trans;
      background: $blue;
      padding: 20px 0;
      text-align: center;
      
      @include mq(md) {
        padding: 0;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 2;
        width: 80%;
        bottom: 50px;
        opacity: 0;
        background: transparent;
      }
    }

    &__name {
      display: block;
      color: $white;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__profile-button {
      display: block;
      color: $white;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.projects-container {
  @include trans;

  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 2000;
  transform: translateX(-100%);

  &.-visible {
    transform: translateX(0);
  }
}
